import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/web/web/app/-providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/web/web/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/web/web/components/frontend-observability.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/home/runner/_work/web/web/components/ui/button.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/web/web/components/ui/field/standard-field.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Input"] */ "/home/runner/_work/web/web/components/ui/input.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/web/web/components/ui/textarea.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tooltip","TooltipTrigger","TooltipContent"] */ "/home/runner/_work/web/web/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/web/web/node_modules/.pnpm/next@15.1.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.49.0_react-d_2de3c545f009e4964cff78dc9d0afded/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/web/web/node_modules/.pnpm/next@15.1.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.49.0_react-d_2de3c545f009e4964cff78dc9d0afded/node_modules/next/font/google/target.css?{\"path\":\"components/email-library/templates/base.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
